<template>
  <v-card class="mb-9">
    <v-card-title class="px-4 py-2 cyan darken-1">
      <h3 class="subtitle-1 font-weight-bold white--text">公務就任</h3>
    </v-card-title>
    <v-simple-table class="transparent my-5">
      <template v-slot:default>
        <tbody>
          <tr>
            <td colspan="2" style="border-bottom: 0px">
              <div class="grey--text text--darken-1 text-caption mt-3">
                上記事務所に係らず、現在、国又は地方公共団体等の公職に就いている場合（規定により業務が禁止されている場合）は入力してください。
              </div>
            </td>
          </tr>
          <tr>
            <th width="30%" class="text-right">公務就任先</th>
            <td>
              <v-text-field
                outlined
                hide-details
                dense
                placeholder=""
                color="cyan darken-2"
                background-color="white"
                v-model="fields.public_servant"
              ></v-text-field>
              <div v-if="errors && errors.public_servant" class="text-danger">
                {{ errors.public_servant[0] }}
              </div>
              <div class="grey--text text--darken-1 text-caption mt-1">
                例：内閣府、特許庁、市役所等
              </div>

              <p class="grey--text text--darken-1 text-caption mt-2 mb-1">公務就任終了日が予め決まっている場合には、公務就任終了日を入力してください</p>
              <div style="width: 200px">
                <v-menu
                    ref="publicServantFinishDateSearch"
                    v-model="publicServantFinishDateSearch"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fields.public_servant_finish"
                        prepend-inner-icon="mdi-calendar"
                        @click:prepend-inner="on.click"
                        label="公務就任終了日"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        dense
                        color="cyan darken-2"
                        background-color="white"
                        class="datepick"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fields.public_servant_finish"
                      :active-picker.sync="activePicker"
                      @input="publicServantFinishDateSearch = false"
                      color="cyan darken-2"
                    >
                    </v-date-picker>
                  </v-menu>

                </div>
                
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      publicServantFinishDateSearch: false,
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr .bbnone {
  border-bottom: none !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 10px 16px;
}
</style>
