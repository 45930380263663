<template>
  <div>
    <transition-group tag="div">
      <v-card
        v-for="(office_entry_request, index) in offices"
        :key="index"
        class="mb-5"
      >
        <v-card-title class="px-4 py-2 green darken-2">
          <h3 class="subtitle-1 font-weight-bold white--text">所属事務所</h3>
        </v-card-title>

        <v-simple-table class="transparent my-5">
          <template v-slot:default>
            <tbody>
              <tr>
                <th
                  width="120"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>事務所状態</span>
                </th>
                <td>
                  <div class="d-md-flex justify-start align-center">
                    <v-checkbox
                      v-model="office_entry_request['office_state_cd']"
                      label="主たる事務所"
                      color="cyan darken-2"
                      @change='changeOperation(office_entry_request)'
                    ></v-checkbox>
                    <div
                      v-if="
                        errors &&
                        errors[
                          'office_entry_requests.' + index + '.office_state_cd'
                        ]
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "office_entry_requests." + index + ".office_state_cd"
                        ][0]
                      }}
                    </div>
                    <div>
                      <v-tooltip right :open-on-hover="false">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            @click="on.click"
                            @blur="on.blur"
                            retain-focus-on-click
                            text
                            color="cyan darken-2"
                            class="mx-md-5 mb-2 mb-md-0"
                          >
                            <v-icon dark depressed color="black">
                              mdi-chat-question-outline
                            </v-icon>
                            <span
                              class="text-caption ml-1 text-decoration-underline"
                              >主たる事務所とは</span
                            >
                          </v-btn>
                        </template>
                        <p class="mb-0">
                          登録する事務所のうち、１ヶ所のみ主たる事務所としてください。<br />
                          それ以外は従たる事務所となります。
                        </p>
                      </v-tooltip>
                    </div>
                    <div>
                      <v-dialog v-model="dialog" width="800">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="red lighten-2"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            text
                            small
                            class="mb-2 mb-md-0"
                          >
                            <v-icon> mdi-alert-circle-outline </v-icon>
                            入力についてのお願い（必読）
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title class="red lighten-4">
                            入力についてのお願い
                          </v-card-title>

                          <v-card-text class="py-4"
                            ><strong
                              >！会内における登録情報の管理の都合上、事務所名称及びフリガナは下記のように入力をお願いします。！</strong
                            ><br />
                            <br />
                            １．フリガナ欄は、スペースを入れず、続けて入力してください。<br />
                            <span class="caption"
                              >[例]（フリガナ）コウオツトッキョジムショトラノモンシショ<br />
                              （事務所名）甲乙特許事務所 虎ノ門支所</span
                            ><br />
                            <br />
                            ２．「株式会社」、「特許業務法人」は、（株）、（業）と省略をしないで、入力してください。<br />
                            <span class="caption"
                              >[例]（事務所名）ABC株式会社<br />
                              知的財産部 特許課 → ○ よい例<br />
                              （事務所名）ABC（株）知的財産部 特許課 → X
                              悪い例</span
                            ><br />
                            <br />
                            ３．「株式会社」、「特許業務法人」の部分には、フリガナはふらないでください。それ以外の名称部分のみフリガナをふってください。<br />
                            <span class="caption"
                              >[例]（フリガナ）
                              エービーシーチテキザイサンブトッキョカ<br />
                              ※「カブシキガイシャ」は不要<br />
                              （事務所名） ABC株式会社 知的財産部 特許課<br />
                              [例]（フリガナ） イロハ<br />
                              ※「トッキョギョウムホウジン」は不要<br />
                              （事務所名）特許業務法人いろは</span
                            ></v-card-text
                          >

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue-grey darken-2"
                              text
                              class="font-weight-bold"
                              @click="dialog = false"
                            >
                              理解しました
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </div>
                  <div class="grey--text text--darken-1 text-caption ml-3">
                    主たる事務所の場合はチェックを入れてください
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>フリガナ</span>
                </th>
                <td>
                  <v-text-field
                    single-line
                    outlined
                    hide-details
                    dense
                    placeholder="トウキョウトッキョジムショ"
                    background-color="white"
                    color="green darken-3"
                    class="mr-md-2"
                    v-model="office_entry_request['name_kana']"
                  ></v-text-field>
                  <div
                    v-if="
                      errors &&
                      errors['office_entry_requests.' + index + '.name_kana']
                    "
                    class="text-danger"
                  >
                    {{
                      errors["office_entry_requests." + index + ".name_kana"][0]
                    }}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>事務所名</span>
                </th>
                <td class="py-2">
                  <v-text-field
                    single-line
                    outlined
                    hide-details
                    dense
                    placeholder="東京特許事務所"
                    background-color="white"
                    class="mr-md-2"
                    color="green darken-3"
                    v-model="office_entry_request['name']"
                  ></v-text-field>
                  <div
                    v-if="
                      errors &&
                      errors['office_entry_requests.' + index + '.name']
                    "
                    class="text-danger"
                  >
                    {{ errors["office_entry_requests." + index + ".name"][0] }}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>事務所所在地</span>
                </th>
                <td class="pb-2">
                  <div class="d-md-flex justify-start py-2">
                    <div style="width: 200px">
                      <v-text-field
                        label="郵便番号"
                        placeholder="郵便番号(ハイフン無し)"
                        outlined
                        hide-details
                        color="green darken-3"
                        background-color="white"
                        dense
                        v-model="office_entry_request['adress_postalcode']"
                        @input="getPrefecture(index, office_entry_request['id'], false)"
                      ></v-text-field>
                      <div
                        v-if="
                          errors &&
                          errors[
                            'office_entry_requests.' +
                              index +
                              '.adress_postalcode'
                          ]
                        "
                        class="text-danger"
                      >
                        {{
                          errors[
                            "office_entry_requests." +
                              index +
                              ".adress_postalcode"
                          ][0]
                        }}
                      </div>
                    </div>
                    <v-btn
                      class="mx-md-2 mr-2 my-4 my-md-0"
                      @click="getPrefecture(index, office_entry_request['id'], true)"
                      >住所検索</v-btn
                    >
                    <SelectRegionButton
                      v-model="office_entry_request['adress_postalcode']"
                      :index="index"
                      @setKaigai="setKaigai"
                    />
                  </div>
                  <div class="d-md-flex justify-start align-center pb-2">
                    <div style="width: 200px">
                      <v-select
                        label="都道府県"
                        :items="[
                          { label: '選択してください', id: '' },
                          ...office_prefecture,
                        ]"
                        item-text="label"
                        item-value="id"
                        outlined
                        hide-details
                        color="green darken-3"
                        background-color="white"
                        flat
                        dense
                        v-model="office_entry_request['adress_prefecture']"
                        @change="getBranchCd(index)"
                      ></v-select>
                    </div>
                    <div
                      v-if="
                        errors &&
                        errors[
                          'office_entry_requests.' +
                            index +
                            '.adress_prefecture'
                        ]
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "office_entry_requests." +
                            index +
                            ".adress_prefecture"
                        ][0]
                      }}
                    </div>
                    <div class="ml-md-2 mt-2 mt-md-0">
                      <v-tooltip bottom :open-on-hover="false">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            @click="on.click"
                            @blur="on.blur"
                            retain-focus-on-click
                            text
                            color="cyan darken-2"
                          >
                            <v-icon dark depressed color="black">
                              mdi-chat-question-outline
                            </v-icon>
                            <span
                              class="text-caption ml-1 text-decoration-underline"
                              >海外に事務所をお持ちの方</span
                            >
                          </v-btn>
                        </template>
                        <p class="mb-0">
                          海外ボタンを押下後、地域を選択し、「住所」欄に住所を入力してください（郵便番号と都道府県欄は自動入力されます）<br> 例：3-4-2，Kasumigaseki，Chiyoda-ku，Tokyo 100-0013 Japan
                        </p>
                      </v-tooltip>
                    </div>
                  </div>
                  <v-text-field
                    label="住所 ※英数字、ハイフン等の記号は半角で入力して下さい"
                    outlined
                    hide-details
                    color="teal darken-3"
                    class="mb-2"
                    background-color="white"
                    dense
                    v-model="office_entry_request['adress_municipality']"
                  ></v-text-field>
                  <div
                    v-if="
                      errors &&
                      errors[
                        'office_entry_requests.' +
                          index +
                          '.adress_municipality'
                      ]
                    "
                    class="text-danger"
                  >
                    {{
                      errors[
                        "office_entry_requests." +
                          index +
                          ".adress_municipality"
                      ][0]
                    }}
                  </div>

                  <v-text-field
                    label="建物名以下 ※英数字、ハイフン等の記号は半角で入力して下さい"
                    outlined
                    hide-details
                    color="teal darken-3"
                    background-color="white"
                    dense
                    class="mb-4 mb-md-0"
                    v-model="office_entry_request['adress_building']"
                  ></v-text-field>
                  <div
                    v-if="
                      errors &&
                      errors[
                        'office_entry_requests.' + index + '.adress_building'
                      ]
                    "
                    class="text-danger"
                  >
                    {{
                      errors[
                        "office_entry_requests." + index + ".adress_building"
                      ][0]
                    }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-simple-table class="transparent my-5">
          <template v-slot:default>
            <tbody>
              <tr>
                <th
                  width="120"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>事務所TEL1</span>
                </th>
                <td>
                  <div class="d-md-flex justify-start align-center">
                    <v-text-field
                      placeholder="例：03-3456-7890"
                      single-line
                      outlined
                      hide-details
                      dense
                      class="mb-md-2 mb-4"
                      background-color="white"
                      color="green darken-3"
                      style="width: 250px"
                      v-model="office_entry_request['tel1']"
                    ></v-text-field>
                    <div
                      v-if="
                        errors &&
                        errors['office_entry_requests.' + index + '.tel1']
                      "
                      class="text-danger"
                    >
                      {{
                        errors["office_entry_requests." + index + ".tel1"][0]
                      }}
                    </div>
                    <v-select
                      label="回線区分"
                      :items="[
                        { label: '選択してください', id: '' },
                        ...line_division,
                      ]"
                      item-text="label"
                      item-value="id"
                      outlined
                      hide-details
                      color="green darken-3"
                      background-color="white"
                      flat
                      dense
                      class="ml-md-5"
                      style="width: 200px"
                      v-model="office_entry_request['tel1_line_division']"
                    ></v-select>
                    <div
                      v-if="
                        errors &&
                        errors[
                          'office_entry_requests.' +
                            index +
                            '.tel1_line_division'
                        ]
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "office_entry_requests." +
                            index +
                            ".tel1_line_division"
                        ][0]
                      }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  width="150"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>内線</span>
                </th>
                <td colspan="2">
                  <v-text-field
                    placeholder="123"
                    single-line
                    outlined
                    hide-details
                    dense
                    class="my-2"
                    background-color="white"
                    color="green darken-3"
                    style="width: 200px"
                    v-model="office_entry_request['tel1_extension']"
                  ></v-text-field>
                  <div
                    v-if="
                      errors &&
                      errors[
                        'office_entry_requests.' + index + '.tel1_extension'
                      ]
                    "
                    class="text-danger"
                  >
                    {{
                      errors[
                        "office_entry_requests." + index + ".tel1_extension"
                      ][0]
                    }}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  width="150"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>事務所TEL2</span>
                </th>
                <td>
                  <div class="d-md-flex justify-start align-center">
                    <v-text-field
                      placeholder="例：03-3456-7890"
                      single-line
                      outlined
                      hide-details
                      dense
                      class="mb-md-2 mb-4"
                      background-color="white"
                      color="green darken-3"
                      style="width: 250px"
                      v-model="office_entry_request['tel2']"
                    ></v-text-field>
                    <div
                      v-if="
                        errors &&
                        errors['office_entry_requests.' + index + '.tel2']
                      "
                      class="text-danger"
                    >
                      {{
                        errors["office_entry_requests." + index + ".tel2"][0]
                      }}
                    </div>
                    <v-select
                      label="回線区分"
                      :items="[
                        { label: '選択してください', id: '' },
                        ...line_division,
                      ]"
                      item-text="label"
                      item-value="id"
                      outlined
                      hide-details
                      color="green darken-3"
                      background-color="white"
                      flat
                      dense
                      class="ml-md-5"
                      style="width: 200px"
                      v-model="office_entry_request['tel2_line_division']"
                    ></v-select>
                    <div
                      v-if="
                        errors &&
                        errors[
                          'office_entry_requests.' +
                            index +
                            '.tel2_line_division'
                        ]
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "office_entry_requests." +
                            index +
                            ".tel2_line_division"
                        ][0]
                      }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  width="150"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>内線</span>
                </th>
                <td colspan="2">
                  <v-text-field
                    placeholder="123"
                    single-line
                    outlined
                    hide-details
                    dense
                    class="my-2"
                    background-color="white"
                    color="green darken-3"
                    v-model="office_entry_request['tel2_extension']"
                    style="width: 200px"
                  ></v-text-field>
                  <div
                    v-if="
                      errors &&
                      errors[
                        'office_entry_requests.' + index + '.tel2_extension'
                      ]
                    "
                    class="text-danger"
                  >
                    {{
                      errors[
                        "office_entry_requests." + index + ".tel2_extension"
                      ][0]
                    }}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>事務所FAX1</span>
                </th>
                <td colspan="2">
                  <v-text-field
                    placeholder="例：03-3456-7890"
                    single-line
                    outlined
                    hide-details
                    dense
                    background-color="white"
                    color="green darken-3"
                    class="my-2"
                    v-model="office_entry_request['fax1']"
                  ></v-text-field>
                  <div
                    v-if="
                      errors &&
                      errors['office_entry_requests.' + index + '.fax1']
                    "
                    class="text-danger"
                  >
                    {{ errors["office_entry_requests." + index + ".fax1"][0] }}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>事務所FAX2</span>
                </th>
                <td colspan="2">
                  <v-text-field
                    placeholder="例：03-3456-7890"
                    single-line
                    outlined
                    hide-details
                    dense
                    class="my-2"
                    background-color="white"
                    color="green darken-3"
                    v-model="office_entry_request['fax2']"
                  ></v-text-field>
                  <div
                    v-if="
                      errors &&
                      errors['office_entry_requests.' + index + '.fax2']
                    "
                    class="text-danger"
                  >
                    {{ errors["office_entry_requests." + index + ".fax2"][0] }}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>就業形態</span>
                </th>
                <td colspan="2">
                  <div class="d-md-flex justify-start align-center">
                    <div style="width: 240px">
                      <v-select v-if="office_entry_request['office_state_cd'] == 1"
                        :items="[
                          { label: '選択してください', id: '' },
                          ...operation_status_cd,
                        ]"
                        item-text="label"
                        item-value="id"
                        outlined
                        hide-details
                        color="green darken-3"
                        background-color="white"
                        flat
                        dense
                        class="caption"
                        v-model="office_entry_request['office_operation_cd']"
                        @change='changeAddressRange(office_entry_request)'
                      ></v-select>
                      <v-select v-else
                        :items="[
                          { label: '選択してください', id: '' },
                          ...reduce_operation_cd,
                        ]"
                        item-text="label"
                        item-value="id"
                        outlined
                        hide-details
                        color="green darken-3"
                        background-color="white"
                        flat
                        dense
                        class="caption"
                        v-model="office_entry_request['office_operation_cd']"
                        @change='changeAddressRange(office_entry_request)'
                      ></v-select>
                      <div
                        v-if="
                          errors &&
                          errors[
                            'office_entry_requests.' +
                              index +
                              '.office_operation_cd'
                          ]
                        "
                        class="text-danger"
                      >
                        {{
                          errors[
                            "office_entry_requests." +
                              index +
                              ".office_operation_cd"
                          ][0]
                        }}
                      </div>
                    </div>
                    <div class="mt-2">
                      <v-tooltip right :open-on-hover="false">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            @click="on.click"
                            @blur="on.blur"
                            retain-focus-on-click
                            text
                            color="cyan darken-2"
                          >
                            <v-icon dark depressed color="black">
                              mdi-chat-question-outline
                            </v-icon>
                            <span
                              class="text-caption ml-1 text-decoration-underline"
                              >勤務証明書が必要な就業形態</span
                            >
                          </v-btn>
                        </template>
                        <p class="mb-0">
                          以下の就業形態を選択された場合には、勤務証明書の添付が必要です<br>
                        ・特許事務所勤務<br>
                        ・企業<br>
                        ・弁理士法人勤務<br>
                        ・弁護士法人・法律事務所勤務<br>
                        ・官公庁、非営利団体勤務<br>
                        ・海外法律事務所等勤務
                        </p>
                      </v-tooltip>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions color="grey">
          <v-spacer></v-spacer>

          <v-btn
            class="ma-2"
            dark
            depressed
            small
            color="red darken-2"
            @click="removeOfficeEntryRequest(index)"
          >
            <v-icon>mdi-trash-can</v-icon>
            この事務所を削除
          </v-btn>
        </v-card-actions>
      </v-card>
    </transition-group>
    <div class="text-center pb-4">
      <v-btn
        class="ma-2"
        dark
        depressed
        outlined
        color="green darken-2"
        @click="createNewOfficeEntryRequest()"
      >
        <v-icon class="mr-2">mdi-domain-plus</v-icon>
        事務所を追加する
      </v-btn>
      <div v-if="errors && errors['office']" class="text-danger">
        {{ errors["office"][0] }}
      </div>
    </div>
  </div>
</template>

<script>
import SelectRegionButton from '../templates/Parts/SelectRegionButton.vue';
export default {
  components: {
    SelectRegionButton,
  },
  data() {
    return {
      dialog: false,
      offices: this.fields["office_entry_requests"],
      office_prefecture: [],
      line_division: [],
      operation_status_cd: [],
      reduce_operation_cd:[],
      addressRange:0,
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.$store.commit("setOverlay", {
      overlay: true,
    });
    (async () => {
      await this.$axios
        .get("/api/masters/office_prefecture")
        .then((response) => {
          this.office_prefecture = response.data;
        });
      await this.$axios.get("/api/masters/line_division").then((response) => {
        this.line_division = response.data;
      });
      await this.$axios
        .get("/api/masters/operation_status_cd")
        .then((response) => {
          //6の弁理士法人経営は新規申請の際は無効とする
          let operationStatusCds = [];
          for (const elem of response.data) {
            if (elem.id != 6) {
              operationStatusCds.push(elem);
            }
          }
          this.operation_status_cd = operationStatusCds;

          let reduceoperationStatusCds = [];
          for (const elem of response.data) {
            if (elem.id != 6 && elem.id != 12) {
              reduceoperationStatusCds.push(elem);
            }
          } 
          this.reduce_operation_cd = reduceoperationStatusCds;
        });
      this.createNewOfficeEntryRequest();
      this.$store.commit("setOverlay", {
        overlay: false,
      });
    })();
  },
  methods: {
    createNewOfficeEntryRequest() {
      let countOffice = Object.keys(this.fields['office_entry_requests']).length;
      if(countOffice >= 6){
        alert("6件以上の所属事務所については事務局までお問い合わせください");
        return false;
      }
      const new_index =
        Object.keys(this.fields["office_entry_requests"]).length + 1;
      for (var i = 1; i < new_index; i++) {
        this.fields["office_entry_requests"][i][
          "show_office_entry_request_flag"
        ] = false;
      }
      this.$set(this.fields["office_entry_requests"], new_index, {
        id: null,
        office_cd: "",
        office_state_cd: "",
        office_operation_cd: "",
        name_kana: null,
        name: null,
        abstract_name_presence: "",
        adress_postalcode: null,
        adress_prefecture: "",
        adress_municipality: null,
        adress_building: null,
        tel1: null,
        tel1_line_division: "",
        tel1_extension: null,
        tel2: null,
        tel2_line_division: "",
        tel2_extension: null,
        fax1: null,
        fax2: null,
        branch_cd: "",
        district_cd: "",
        NotifactionMenu: false,
        show_office_entry_request_flag: true,
        delete_flag: false,
        update_request_time: this.fields["entry_requested_at"],
        office_number: "",
        office: {
          name: "",
        },
      });
      this.offices = {};
      for (var j = 1; j <= new_index; j++) {
        if (this.fields["office_entry_requests"][j]["delete_flag"] == false) {
          this.offices[j] = this.fields["office_entry_requests"][j];
        }
      }
    },
    removeOfficeEntryRequest(index) {
      if (window.confirm("事務所情報を削除してよろしいですか？")) {
        this.fields["office_entry_requests"][index]["delete_flag"] = true;
        this.fields["office_entry_requests"][index][
          "show_office_entry_request_flag"
        ] = false;
        const newOfficeEntryRequest = {};
        var i = 1;
        for (const [key, value] of Object.entries(
          this.fields["office_entry_requests"]
        )) {
          if(value['delete_flag'] == false){
            this.$set(newOfficeEntryRequest, i, value);
            i++;
          }
        }
        this.fields["office_entry_requests"] = newOfficeEntryRequest;
        this.offices = {};
        for (
          var j = 1;
          j < Object.keys(this.fields["office_entry_requests"]).length + 1;
          j++
        ) {
          if (this.fields["office_entry_requests"][j]["delete_flag"] == false) {
            this.offices[j] = this.fields["office_entry_requests"][j];
          }
        }
      }
    },
    getPrefecture(index, id, isClicked) {
      let _this = this.fields;
      let that = this;
      if(_this["office_entry_requests"][index]["adress_postalcode"].length == 7){
        if (
          _this["office_entry_requests"][index]["adress_postalcode"].substr(
            0,
            3
          ) == "000"
        ) 
        {
          if(isClicked === true){
            _this["office_entry_requests"][index]["adress_prefecture"] = "";
            _this["office_entry_requests"][index]["adress_municipality"] = "";
            _this["office_entry_requests"][index]["adress_building"] = "";
          }
          that.$axios.get("/api/getBranchDistrictCd/"+_this["office_entry_requests"][index]["adress_postalcode"]+"/false/false").then((response) => {
            if(response.data.branch_cd != ''){
              _this["office_entry_requests"][index]["branch_cd"] = response.data.branch_cd;
            }
            if(response.data.district_cd != ''){
              _this["office_entry_requests"][index]["district_cd"] = response.data.district_cd;
            }
          });
        } else {
          this.getAddress(
            _this["office_entry_requests"][index]["adress_postalcode"],
            function (addr) {
            if(isClicked === true){
              //住所検索ボタンを押した時にのみ住所を自動入力
              _this["office_entry_requests"][index]["adress_prefecture"] = addr.prefecture;
              _this["office_entry_requests"][index]["adress_municipality"] = addr.municipality;
              _this["office_entry_requests"][index]["adress_building"] = ""; //住所検索ボタンを押した時は「建物名以下」は空欄にする
            }
            if(addr.region != ""){
              var postalcode = (_this["office_entry_requests"][index]["adress_postalcode"])?_this["office_entry_requests"][index]["adress_postalcode"]:0;
              that.$axios.get("/api/getBranchDistrictCd/"+postalcode+"/"+addr.region+"/"+addr.locality).then((response) => {
                if(response.data.branch_cd != ''){
                  _this["office_entry_requests"][index]["branch_cd"] = response.data.branch_cd;
                }
                if(response.data.district_cd != ''){
                  _this["office_entry_requests"][index]["district_cd"] = response.data.district_cd;
                }
              });
            }
          });
        }
      }
    },
    setKaigai(index) {
      let _this = this.fields;
      let that = this;
      _this["office_entry_requests"][index]["adress_prefecture"] = '海外';
      _this["office_entry_requests"][index]["adress_municipality"] = "";
      that.$axios.get("/api/getBranchDistrictCd/"+_this["office_entry_requests"][index]["adress_postalcode"]+"/false/false").then((response) => {
        _this["office_entry_requests"][index]["branch_cd"] = response.data.branch_cd;
        _this["office_entry_requests"][index]["district_cd"] = response.data.district_cd;
      });
    },

    getBranchCd(index){
      let _this = this.fields;
      let prefecture = _this["office_entry_requests"][index]["adress_prefecture"];
      if (prefecture == '海外' || prefecture == ''){
        _this["office_entry_requests"][index]["adress_postalcode"] = '0000000';
      }else{
        var postalcode = (_this["office_entry_requests"][index]["adress_postalcode"])?_this["office_entry_requests"][index]["adress_postalcode"]:0;
        var municipality = (_this["office_entry_requests"][index]["adress_municipality"])?_this["office_entry_requests"][index]["adress_municipality"]:0;
        if(postalcode !== 0){
          this.getAddress(postalcode, function (addr) {
            municipality = addr.municipality;
            this.$axios.get("/api/getBranchDistrictCd/"+postalcode +"/"+prefecture+"/"+municipality).then((response) => {
              if(response.data.branch_cd != ''){
                _this["office_entry_requests"][index]["branch_cd"] = response.data.branch_cd;
              }
              if(response.data.district_cd != ''){
                _this["office_entry_requests"][index]["district_cd"] = response.data.district_cd;
              }
            }).catch((error) => {
              _this["office_entry_requests"][index]["branch_cd"] = '';
              _this["office_entry_requests"][index]["district_cd"] = '';
            });
          });
        }
      }
    },

    changeOperation(office) {
      if (this.fields.address_range == true && office.office_state_cd == 1) {
        office.office_operation_cd = 12;
      }else if(office.office_state_cd != 1){
        if(office.office_operation_cd == 12){
          office.office_operation_cd = '';
        }
        this.changeAddressRange();
      }
    },
    changeAddressRange(){
      this.addressRange = 0;
      for (const [index, office] of Object.entries(this.fields['office_entry_requests'])){
        if(office.office_operation_cd == 12){
          this.addressRange++; 
        }
      }
      this.fields.address_range = false;
      this.ShowAddressRange();
    },
    ShowAddressRange() {
      let range = false;
      if(this.addressRange != 0){
        range = true;
      }
      this.$emit("ShowAddressRange",range);
    },
  },
};
</script>

<style type="scss" scoped>
.active {
  background-color: #e8f5e9 !important;
}
.v-enter-active,
.v-leave-active,
.v-move {
  transition: opacity 0.3s;
}
.v-leave-active {
  /*    position: absolute; */
}
.v-enter {
  opacity: 0;
}
.v-leave-to {
  opacity: 0;
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr .bbnone {
  border-bottom: none !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 10px 16px;
}
</style>
