<template>
  <span>
    <v-btn @click="dialog = true" :disabled=disabled class="ml-2">海外{{ selectedRegionText ? ` (${selectedRegionText})` : '' }}</v-btn>

    <v-dialog v-model="dialog" width="500">
      <div>
        <v-card>
          <v-card-text>
            <v-row justify="center" align="center">
              <v-col lg="10">
                <v-card color="#f5fff0" class="mt-0">
                  <v-select
                    label="地域を選択してください"
                    v-model="selectedRegion"
                    :items="items"
                    item-text="text"
                    item-value="id"
                    outlined
                    hide-details
                    color="light-green darken-3"
                    background-color="white"
                    flat
                    dense
                  ></v-select>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="confirmSelection()">
              決定
            </v-btn>
            <v-btn color="primary" text @click="dialog = false">
              キャンセル
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    index: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    office: {}
  },
  data() {
    return {
      dialog: false,
      selectedRegion: this.value,
      selectedRegionText: "",
      items: [
        { text: "選択してください", id: "" },
        { text: "アジア", id: "0000001" },
        { text: "大洋州", id: "0000002" },
        { text: "アフリカ", id: "0000003" },
        { text: "ヨーロッパ", id: "0000004" },
        { text: "北中米", id: "0000006" },
        { text: "南米", id: "0000007" },
        { text: "中東", id: "0000008" }
      ]
    };
  },
  mounted() {
    this.getSelectedRegionText();
  },
  watch: {
    value(newValue) {
      this.selectedRegion = newValue;
      // ボタン表示テキストの更新
      this.getSelectedRegionText();
    }
  },
  methods: {
    confirmSelection() {
      // 親の郵便番号を更新
      this.$emit("input", this.selectedRegion);
      
      // setkaigaiを発火
      // officeが指定されている時
      if(this.office !== undefined) {
        this.$emit("setKaigai", this.office);
      }
      // indexが指定されている時
      else if(this.index !== undefined) {
        this.$emit("setKaigai", this.index); // 引数付きでカスタムイベント発火
      }
      // それ以外
      else{
        this.$emit("setKaigai"); // 引数なしでカスタムイベント発火
      }
      this.dialog = false;
    },
    closeDialog() {
      this.dialog = false;
    },
    getSelectedRegionText() {
      this.selectedRegionText = '';
      if(this.selectedRegion != ''){
        const region = this.items.find(item => item.id === this.selectedRegion);
        this.selectedRegionText = region ? region.text : "";
      }
    }
  }
};
</script>
