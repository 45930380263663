var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition-group',{attrs:{"tag":"div"}},[_vm._l((_vm.offices),function(office,index){return [(
            office['delete_flag'] == false && office['old_office_state_cd'] != 4
          )?_c('v-card',{key:index,staticClass:"mb-5"},[_c('v-card-title',{staticClass:"px-4 py-2 green darken-2"},[_c('h3',{staticClass:"subtitle-1 font-weight-bold white--text"},[_vm._v("所属事務所")])]),_c('v-simple-table',{staticClass:"transparent my-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',{class:{
                    active: _vm.changes.office_state_change_presence[index],
                  }},[_c('th',{staticClass:"text-md-right px-3 bg-yellow lighten-5",staticStyle:{"vertical-align":"middle"},attrs:{"width":"150"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"green darken-3","disabled":office.office_id == null},model:{value:(_vm.changes.office_state_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.office_state_change_presence, index, $$v)},expression:"changes.office_state_change_presence[index]"}}),_c('span',[_vm._v("事務所状態")])],1)]),_c('td',[(office.office_state_cd != 3)?_c('div',{staticClass:"d-md-flex justify-start align-center"},[_c('v-radio-group',{attrs:{"row":"","dense":""},on:{"change":function($event){return _vm.changeOperation(office, index)}},model:{value:(office.office_state_cd),callback:function ($$v) {_vm.$set(office, "office_state_cd", $$v)},expression:"office.office_state_cd"}},_vm._l((_vm.office_state_cd),function(cd){return _c('v-radio',{key:cd.id,attrs:{"label":cd.label,"value":cd.id,"color":"green darken-3","disabled":!_vm.changes.office_state_change_presence[index]}})}),1)],1):_c('div',{staticClass:"d-md-flex justify-start align-center"},[_c('v-radio-group',{attrs:{"row":"","dense":""},on:{"change":function($event){return _vm.changeOperation(office, index)}},model:{value:(office.office_state_cd),callback:function ($$v) {_vm.$set(office, "office_state_cd", $$v)},expression:"office.office_state_cd"}},_vm._l((_vm.office_state_cd_with_2),function(cd){return _c('v-radio',{key:cd.id,attrs:{"label":cd.label,"value":cd.id,"color":"green darken-3","disabled":!_vm.changes.office_state_change_presence[index]}})}),1)],1),_c('div',[_c('v-tooltip',{attrs:{"right":"","open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',{attrs:{"small":"","retain-focus-on-click":"","text":"","color":"cyan darken-2"},on:{"click":on.click,"blur":on.blur}},[_c('v-icon',{attrs:{"dark":"","depressed":"","color":"black"}},[_vm._v(" mdi-chat-question-outline ")]),_c('span',{staticClass:"\n                                  text-caption\n                                  ml-1\n                                  text-decoration-underline\n                                "},[_vm._v("主たる事務所とは")])],1)]}}],null,true)},[_c('p',{staticClass:"mb-0"},[_vm._v(" 登録する事務所のうち、１ヶ所のみ主たる事務所としてください。"),_c('br'),_vm._v(" それ以外は従たる事務所となります。 ")])])],1),(
                        _vm.errors &&
                        _vm.errors['belong_offices.' + index + '.office_state_cd']
                      )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".office_state_cd" ][0])+" ")]):_vm._e()])]),(office.office_state_cd == 4)?_c('tr',{class:{
                    active: _vm.changes.retirement_date_change_presence[index],
                  }},[_c('th',{staticClass:"text-md-right px-3 bg-yellow lighten-5",staticStyle:{"vertical-align":"middle"},attrs:{"width":"150"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"green darken-3"},model:{value:(_vm.changes.retirement_date_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.retirement_date_change_presence, index, $$v)},expression:"changes.retirement_date_change_presence[index]"}}),_c('span',[_vm._v("前勤務先退職日")])],1)]),_c('td',[_c('div',{staticStyle:{"width":"200px"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"datepick",attrs:{"prepend-inner-icon":"mdi-calendar","readonly":"","solo":"","dense":"","flat":"","outlined":"","hide-details":"","color":"teal darken-3","background-color":"white","clearable":"","disabled":!_vm.changes.retirement_date_change_presence[index]},model:{value:(office.retirement_date),callback:function ($$v) {_vm.$set(office, "retirement_date", $$v)},expression:"office.retirement_date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(office.retirementDateMenu),callback:function ($$v) {_vm.$set(office, "retirementDateMenu", $$v)},expression:"office.retirementDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","day-format":function (date) { return new Date(date).getDate(); },"max":_vm.fields.change_requested_at},on:{"input":function($event){office.retirementDateMenu=false}},model:{value:(office.retirement_date),callback:function ($$v) {_vm.$set(office, "retirement_date", $$v)},expression:"office.retirement_date"}})],1)],1),_c('div',{staticClass:"text-caption m1",staticStyle:{"color":"#0097A7"}},[_vm._v(" 事務所・企業・団体等に勤務しており、退職・出向・資格変更（勤務から経営になった場合等）による変更が生じた場合は、その日付を入力してください。 ＊経営していた事務所の廃止や、同じ組織内での変更（部署異動等）の場合には入力不要です。 ")]),(
                        _vm.errors &&
                        _vm.errors['belong_offices.' + index + '.retirement_date']
                      )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".retirement_date" ][0])+" ")]):_vm._e()])]):_vm._e(),_c('tr',{class:{ active: _vm.changes.name_change_presence[index] }},[_c('th',{staticClass:"text-md-right px-3 bg-yellow lighten-5 bbnone",staticStyle:{"vertical-align":"middle"},attrs:{"width":"150"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"green darken-3","disabled":office.office_id == null},model:{value:(_vm.changes.name_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.name_change_presence, index, $$v)},expression:"changes.name_change_presence[index]"}}),_c('span',[_vm._v("フリガナ")])],1)]),_c('td',{staticClass:"bbnone"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"single-line":"","outlined":"","hide-details":"","dense":"","value":"トウキョウトッキョジムショ","background-color":"white","color":"green darken-3","disabled":!_vm.changes.name_change_presence[index]},model:{value:(office.office_kana),callback:function ($$v) {_vm.$set(office, "office_kana", $$v)},expression:"office.office_kana"}}),(
                        _vm.errors &&
                        _vm.errors['belong_offices.' + index + '.office_kana']
                      )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".office_kana"][0])+" ")]):_vm._e()],1)]),_c('tr',{class:{ active: _vm.changes.name_change_presence[index] }},[_c('th',{staticClass:"text-md-right px-3 bg-yellow lighten-5",staticStyle:{"vertical-align":"middle"},attrs:{"width":"150"}},[_c('div',{staticClass:"d-md-flex justify-end align-center"},[_c('span',[_vm._v("事務所名")])])]),_c('td',{staticClass:"py-2"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"single-line":"","outlined":"","hide-details":"","dense":"","value":"東京特許事務所","background-color":"white","color":"green darken-3","disabled":!_vm.changes.name_change_presence[index]},model:{value:(office.offfice_kanji),callback:function ($$v) {_vm.$set(office, "offfice_kanji", $$v)},expression:"office.offfice_kanji"}}),(
                        _vm.errors &&
                        _vm.errors['belong_offices.' + index + '.offfice_kanji']
                      )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".offfice_kanji"][0])+" ")]):_vm._e()],1)]),_c('tr',{class:{ active: _vm.changes.adress_change_presence[index] }},[_c('th',{staticClass:"text-md-right px-3 bg-yellow lighten-5",staticStyle:{"vertical-align":"middle"},attrs:{"width":"150"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"green darken-3","disabled":office.office_id == null},model:{value:(_vm.changes.adress_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.adress_change_presence, index, $$v)},expression:"changes.adress_change_presence[index]"}}),_c('span',[_vm._v("事務所所在地")])],1)]),_c('td',{staticClass:"pb-2"},[_c('div',{staticClass:"d-md-flex justify-start py-2"},[_c('div',{staticStyle:{"width":"200px"}},[_c('v-text-field',{staticClass:"mb-2 mb-md-0",attrs:{"label":"郵便番号(ハイフン無し)","outlined":"","hide-details":"","color":"green darken-3","background-color":"white","dense":"","disabled":!_vm.changes.adress_change_presence[index]},on:{"input":function($event){return _vm.getPrefecture(office, false)}},model:{value:(office.office_postalcode),callback:function ($$v) {_vm.$set(office, "office_postalcode", $$v)},expression:"office.office_postalcode"}})],1),_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":!_vm.changes.adress_change_presence[index]},on:{"click":function($event){return _vm.getPrefecture(office, true)}}},[_vm._v("住所検索 ")]),_c('SelectRegionButton',{attrs:{"disabled":!_vm.changes.adress_change_presence[index],"office":office},on:{"setKaigai":_vm.setKaigai},model:{value:(office.office_postalcode),callback:function ($$v) {_vm.$set(office, "office_postalcode", $$v)},expression:"office.office_postalcode"}})],1),(
                        _vm.errors &&
                        _vm.errors['belong_offices.' + index + '.office_postalcode']
                      )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".office_postalcode" ][0])+" ")]):_vm._e(),_c('div',{staticClass:"\n                        d-md-flex\n                        justify-start\n                        align-center\n                        pb-2\n                        mt-4 mt-md-0\n                      "},[_c('div',{staticStyle:{"width":"200px"}},[_c('v-select',{attrs:{"label":"都道府県","items":_vm.office_prefecture,"item-text":"label","item-value":"value","outlined":"","hide-details":"","color":"green darken-3","background-color":"white","flat":"","dense":"","disabled":!_vm.changes.adress_change_presence[index]},on:{"change":function($event){return _vm.getBranchCd(office)}},model:{value:(office.office_prefecture),callback:function ($$v) {_vm.$set(office, "office_prefecture", $$v)},expression:"office.office_prefecture"}}),(
                            _vm.errors &&
                            _vm.errors[
                              'belong_offices.' + index + '.office_prefecture'
                            ]
                          )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".office_prefecture" ][0])+" ")]):_vm._e()],1),_c('div',{staticClass:"ml-md-2 my-2 my-md-0"},[_c('v-tooltip',{attrs:{"right":"","open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',{attrs:{"small":"","retain-focus-on-click":"","text":"","color":"cyan darken-2"},on:{"click":on.click,"blur":on.blur}},[_c('v-icon',{attrs:{"dark":"","depressed":"","color":"black"}},[_vm._v(" mdi-chat-question-outline ")]),_c('span',{staticClass:"\n                                  text-caption\n                                  ml-1\n                                  text-decoration-underline\n                                "},[_vm._v("海外に事務所をお持ちの方")])],1)]}}],null,true)},[_c('p',{staticClass:"mb-0"},[_vm._v(" 海外ボタンを押下後、地域を選択し、「住所」欄に住所を入力してください（郵便番号と都道府県欄は自動入力されます）"),_c('br'),_vm._v(" 例：3-4-2，Kasumigaseki，Chiyoda-ku，Tokyo 100-0013 Japan ")])])],1)]),_c('div',{staticClass:"grey--text text--darken-1 text-caption"},[_vm._v(" 英数字、ハイフン等の記号は半角で入力して下さい ")]),_c('v-text-field',{staticClass:"mb-2",attrs:{"label":"住所","outlined":"","hide-details":"","color":"teal darken-3","background-color":"white","dense":"","disabled":!_vm.changes.adress_change_presence[index]},on:{"blur":function($event){return _vm.getBranchCd(office)}},model:{value:(office.office_municipality),callback:function ($$v) {_vm.$set(office, "office_municipality", $$v)},expression:"office.office_municipality"}}),(
                        _vm.errors &&
                        _vm.errors[
                          'belong_offices.' + index + '.office_municipality'
                        ]
                      )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".office_municipality" ][0])+" ")]):_vm._e(),_c('v-text-field',{attrs:{"label":"建物名以下","outlined":"","hide-details":"","color":"teal darken-3","background-color":"white","dense":"","disabled":!_vm.changes.adress_change_presence[index]},model:{value:(office.office_building),callback:function ($$v) {_vm.$set(office, "office_building", $$v)},expression:"office.office_building"}}),(
                        _vm.errors &&
                        _vm.errors['belong_offices.' + index + '.office_building']
                      )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".office_building" ][0])+" ")]):_vm._e()],1)])])]},proxy:true}],null,true)}),_c('v-simple-table',{staticClass:"transparent my-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',{class:{ active: _vm.changes.tel1_change_presence[index] }},[_c('th',{staticClass:"text-md-right px-3 bg-yellow lighten-5",staticStyle:{"vertical-align":"middle"},attrs:{"width":"110"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"green darken-3"},model:{value:(_vm.changes.tel1_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.tel1_change_presence, index, $$v)},expression:"changes.tel1_change_presence[index]"}}),_c('span',[_vm._v("事務所TEL1")])],1)]),_c('td',[_c('div',{staticClass:"d-md-flex justify-start align-center"},[_c('v-text-field',{staticClass:"my-2 mr-md-4",attrs:{"placeholder":"例：03-3456-7890","single-line":"","outlined":"","hide-details":"","dense":"","disabled":!_vm.changes.tel1_change_presence[index],"background-color":"white","color":"green darken-3"},model:{value:(office.tel1),callback:function ($$v) {_vm.$set(office, "tel1", $$v)},expression:"office.tel1"}}),(
                          _vm.errors && _vm.errors['belong_offices.' + index + '.tel1']
                        )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".tel1"][0])+" ")]):_vm._e(),_c('v-select',{attrs:{"label":"回線区分","items":[{ label: '選択してください', id: '' } ].concat( _vm.line_division),"item-text":"label","item-value":"id","outlined":"","hide-details":"","color":"green darken-3","background-color":"white","flat":"","dense":"","disabled":!_vm.changes.tel1_change_presence[index]},model:{value:(office.tel1_line_division),callback:function ($$v) {_vm.$set(office, "tel1_line_division", $$v)},expression:"office.tel1_line_division"}}),(
                          _vm.errors &&
                          _vm.errors[
                            'belong_offices.' + index + '.tel1_line_division'
                          ]
                        )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".tel1_line_division" ][0])+" ")]):_vm._e()],1)])]),_c('tr',{class:{
                    active: _vm.changes.tel1_extension_change_presence[index],
                  }},[_c('th',{staticClass:"text-md-right px-3 bg-yellow lighten-5",staticStyle:{"vertical-align":"middle"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"green darken-3"},model:{value:(_vm.changes.tel1_extension_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.tel1_extension_change_presence, index, $$v)},expression:"changes.tel1_extension_change_presence[index]"}}),_c('span',[_vm._v("内線")])],1)]),_c('td',{attrs:{"colspan":"2"}},[_c('v-text-field',{staticClass:"my-2",attrs:{"value":"03-3456-7890","single-line":"","outlined":"","hide-details":"","dense":"","disabled":!_vm.changes.tel1_extension_change_presence[index],"background-color":"white","color":"green darken-3"},model:{value:(office.tel1_extension),callback:function ($$v) {_vm.$set(office, "tel1_extension", $$v)},expression:"office.tel1_extension"}}),(
                        _vm.errors &&
                        _vm.errors['belong_offices.' + index + '.tel1_extension']
                      )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".tel1_extension"][0])+" ")]):_vm._e()],1)]),_c('tr',{class:{ active: _vm.changes.tel2_change_presence[index] }},[_c('th',{staticClass:"text-md-right px-3 bg-yellow lighten-5",staticStyle:{"vertical-align":"middle"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"green darken-3"},model:{value:(_vm.changes.tel2_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.tel2_change_presence, index, $$v)},expression:"changes.tel2_change_presence[index]"}}),_c('span',[_vm._v("事務所TEL2")])],1)]),_c('td',[_c('div',{staticClass:"d-md-flex justify-start align-center"},[_c('v-text-field',{staticClass:"my-2 mr-md-4",attrs:{"placeholder":"例：03-3456-7890","single-line":"","outlined":"","hide-details":"","dense":"","disabled":!_vm.changes.tel2_change_presence[index],"background-color":"white","color":"green darken-3"},model:{value:(office.tel2),callback:function ($$v) {_vm.$set(office, "tel2", $$v)},expression:"office.tel2"}}),(
                          _vm.errors && _vm.errors['belong_offices.' + index + '.tel2']
                        )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".tel2"][0])+" ")]):_vm._e(),_c('v-select',{attrs:{"label":"回線区分","items":[{ label: '選択してください', id: '' } ].concat( _vm.line_division),"item-text":"label","item-value":"id","outlined":"","hide-details":"","color":"green darken-3","background-color":"white","flat":"","dense":"","disabled":!_vm.changes.tel2_change_presence[index]},model:{value:(office.tel2_line_division),callback:function ($$v) {_vm.$set(office, "tel2_line_division", $$v)},expression:"office.tel2_line_division"}}),(
                          _vm.errors &&
                          _vm.errors[
                            'belong_offices.' + index + '.tel2_line_division'
                          ]
                        )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".tel2_line_division" ][0])+" ")]):_vm._e()],1)])]),_c('tr',{class:{
                    active: _vm.changes.tel2_extension_change_presence[index],
                  }},[_c('th',{staticClass:"text-md-right px-3 bg-yellow lighten-5",staticStyle:{"vertical-align":"middle"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"green darken-3"},model:{value:(_vm.changes.tel2_extension_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.tel2_extension_change_presence, index, $$v)},expression:"changes.tel2_extension_change_presence[index]"}}),_c('span',[_vm._v("内線")])],1)]),_c('td',{attrs:{"colspan":"2"}},[_c('v-text-field',{staticClass:"my-2",attrs:{"value":"03-3456-7890","single-line":"","outlined":"","hide-details":"","dense":"","disabled":!_vm.changes.tel2_extension_change_presence[index],"background-color":"white","color":"green darken-3"},model:{value:(office.tel2_extension),callback:function ($$v) {_vm.$set(office, "tel2_extension", $$v)},expression:"office.tel2_extension"}}),(
                        _vm.errors &&
                        _vm.errors['belong_offices.' + index + '.tel2_extension']
                      )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".tel2_extension"][0])+" ")]):_vm._e()],1)]),_c('tr',{class:{ active: _vm.changes.fax1_change_presence[index] }},[_c('th',{staticClass:"text-md-right px-3 bg-yellow lighten-5",staticStyle:{"vertical-align":"middle"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"green darken-3"},model:{value:(_vm.changes.fax1_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.fax1_change_presence, index, $$v)},expression:"changes.fax1_change_presence[index]"}}),_c('span',[_vm._v("事務所FAX1")])],1)]),_c('td',{attrs:{"colspan":"2"}},[_c('v-text-field',{staticClass:"my-2",attrs:{"placeholder":"例：03-3456-7890","single-line":"","outlined":"","hide-details":"","dense":"","disabled":!_vm.changes.fax1_change_presence[index],"background-color":"white","color":"green darken-3"},model:{value:(office.fax1),callback:function ($$v) {_vm.$set(office, "fax1", $$v)},expression:"office.fax1"}}),(
                        _vm.errors && _vm.errors['belong_offices.' + index + '.fax1']
                      )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".fax1"][0])+" ")]):_vm._e()],1)]),_c('tr',{class:{ active: _vm.changes.fax2_change_presence[index] }},[_c('th',{staticClass:"text-md-right px-3 bg-yellow lighten-5",staticStyle:{"vertical-align":"middle"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"green darken-3"},model:{value:(_vm.changes.fax2_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.fax2_change_presence, index, $$v)},expression:"changes.fax2_change_presence[index]"}}),_c('span',[_vm._v("事務所FAX2")])],1)]),_c('td',{attrs:{"colspan":"2"}},[_c('v-text-field',{staticClass:"my-2",attrs:{"placeholder":"例：03-3456-7890","single-line":"","outlined":"","hide-details":"","dense":"","disabled":!_vm.changes.fax2_change_presence[index],"background-color":"white","color":"green darken-3"},model:{value:(office.fax2),callback:function ($$v) {_vm.$set(office, "fax2", $$v)},expression:"office.fax2"}}),(
                        _vm.errors && _vm.errors['belong_offices.' + index + '.fax2']
                      )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".fax2"][0])+" ")]):_vm._e()],1)]),_c('tr',{class:{
                    active: _vm.changes.office_oparation_change_presence[index],
                  }},[_c('th',{staticClass:"text-md-right px-3 bg-yellow lighten-5",staticStyle:{"vertical-align":"middle"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"green darken-3","disabled":office.office_id == null},model:{value:(
                          _vm.changes.office_oparation_change_presence[index]
                        ),callback:function ($$v) {_vm.$set(_vm.changes.office_oparation_change_presence, index, $$v)},expression:"\n                          changes.office_oparation_change_presence[index]\n                        "}}),_c('span',[_vm._v("就業形態")])],1)]),_c('td',{attrs:{"colspan":"2"}},[(office['office_state_cd'] == 1)?_c('v-select',{attrs:{"items":_vm.operation_status_cd,"item-text":"label","item-value":"id","outlined":"","hide-details":"","color":"green darken-3","background-color":"white","flat":"","dense":"","disabled":!_vm.changes.office_oparation_change_presence[index]},on:{"change":function($event){return _vm.changeAddressRange(office, index)}},model:{value:(office.operation_status_cd),callback:function ($$v) {_vm.$set(office, "operation_status_cd", $$v)},expression:"office.operation_status_cd"}}):_c('v-select',{staticClass:"caption",attrs:{"items":[
                        { label: '選択してください', id: '' } ].concat( _vm.reduce_operation_cd ),"item-text":"label","item-value":"id","outlined":"","hide-details":"","color":"green darken-3","background-color":"white","flat":"","dense":"","disabled":!_vm.changes.office_oparation_change_presence[index]},on:{"change":function($event){return _vm.changeAddressRange(office, index)}},model:{value:(office['operation_status_cd']),callback:function ($$v) {_vm.$set(office, 'operation_status_cd', $$v)},expression:"office['operation_status_cd']"}}),(
                        _vm.errors &&
                        _vm.errors[
                          'belong_offices.' + index + '.operation_status_cd'
                        ]
                      )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".operation_status_cd" ][0])+" ")]):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('v-tooltip',{attrs:{"right":"","open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',{attrs:{"small":"","retain-focus-on-click":"","text":"","color":"cyan darken-2"},on:{"click":on.click,"blur":on.blur}},[_c('v-icon',{attrs:{"dark":"","depressed":"","color":"black"}},[_vm._v(" mdi-chat-question-outline ")]),_c('span',{staticClass:"\n                                text-caption\n                                ml-1\n                                text-decoration-underline\n                              "},[_vm._v("勤務証明書が必要な就業形態")])],1)]}}],null,true)},[_c('p',{staticClass:"mb-0"},[_vm._v(" 以下の就業形態を選択された場合には、勤務証明書の添付が必要です"),_c('br'),_vm._v(" ・特許事務所勤務"),_c('br'),_vm._v(" ・企業"),_c('br'),_vm._v(" ・弁理士法人勤務"),_c('br'),_vm._v(" ・弁護士法人・法律事務所勤務"),_c('br'),_vm._v(" ・官公庁、非営利団体勤務"),_c('br'),_vm._v(" ・海外法律事務所等勤務 ")])])],1)],1)])])]},proxy:true}],null,true)}),(
              office['office_state_cd'] == 1 &&
              office['operation_status_cd'] == 12
            )?_c('v-simple-table',{staticClass:"transparent my-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',{class:{
                    active: _vm.personChanges.address_range_change_presence,
                  }},[_c('th',{staticClass:"text-md-right text-left px-3 bg-yellow lighten-5",staticStyle:{"vertical-align":"middle"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"green darken-3"},model:{value:(_vm.personChanges.address_range_change_presence),callback:function ($$v) {_vm.$set(_vm.personChanges, "address_range_change_presence", $$v)},expression:"personChanges.address_range_change_presence"}}),_c('span',[_vm._v("住所公開範囲")])],1)]),_c('td',[_c('v-checkbox',{attrs:{"label":"主たる事務所の住所公開範囲を市区町村までに留める","color":"teal darken-1","disabled":!_vm.personChanges.address_range_change_presence},model:{value:(_vm.fields.address_range),callback:function ($$v) {_vm.$set(_vm.fields, "address_range", $$v)},expression:"fields.address_range"}}),(_vm.errors && _vm.errors.address_range)?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.address_range[0])+" ")]):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('v-tooltip',{attrs:{"right":"","open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',{attrs:{"small":"","retain-focus-on-click":"","text":"","color":"cyan darken-2"},on:{"click":on.click,"blur":on.blur}},[_c('v-icon',{attrs:{"dark":"","depressed":"","color":"black"}},[_vm._v(" mdi-chat-question-outline ")]),_c('span',{staticClass:"\n                                text-caption\n                                ml-1\n                                text-decoration-underline\n                              "},[_vm._v("住所の公開範囲")])],1)]}}],null,true)},[_c('p',{staticClass:"mb-0"},[_vm._v(" 事業用ではなく専ら本会からの連絡を受ける用途の事務所を自宅に設置した場合にのみ選択できます。"),_c('br'),_vm._v("選択すると、主たる事務所の就業形態が自動的に連絡先用事務所になります。 ")])])],1)],1)])])]},proxy:true}],null,true)}):_vm._e(),_c('v-card-actions',{attrs:{"color":"grey"}},[_c('v-spacer'),(index > _vm.officesLength)?_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","depressed":"","small":"","color":"red darken-2"},on:{"click":function($event){$event.stopPropagation();return _vm.removeOffice(index)}}},[_c('v-icon',[_vm._v("mdi-trash-can")]),_vm._v(" この事務所を削除 ")],1):_vm._e()],1)],1):_vm._e()]})],2),_c('div',{staticClass:"text-center pb-4"},[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","depressed":"","outlined":"","color":"green darken-2"},on:{"click":_vm.addOffice}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-domain-plus")]),_vm._v(" 事務所を追加する ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }