<template>
  <v-card class="mb-9">
    <v-card-title class="px-4 py-2 cyan darken-1">
      <h3 class="subtitle-1 font-weight-bold white--text">自宅情報</h3>
    </v-card-title>
    <v-simple-table class="transparent mt-5">
      <template v-slot:default>
        <tbody>
          <tr>
            <th
              width="100"
              class="text-right px-3 bg-yellow lighten-5"
              style="vertical-align: middle"
            >
              <span>住所</span>
            </th>
            <td class="pb-2">
              <div class="d-md-flex justify-start align-center pb-8 pb-md-4">
                <div style="width: 200px">
                  <v-text-field
                    label="郵便番号(ハイフン無し)"
                    outlined
                    hide-details
                    color="cyan darken-2"
                    background-color="white"
                    dense
                    v-model="fields.adress_postalcode"
                  ></v-text-field>
                </div>
                
                  <v-btn class="mx-md-2 mr-2" @click="getPrefecture"
                    >住所検索</v-btn
                  >
                  <SelectRegionButton
                      v-model="fields.adress_postalcode"
                      @setKaigai="setKaigai"
                    />
               
                <div class="ml-md-3">
                  <v-tooltip bottom :open-on-hover="false">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        @click="on.click"
                        @blur="on.blur"
                        retain-focus-on-click
                        text
                        color="cyan darken-2"
                      >
                        <v-icon dark depressed color="black">
                          mdi-chat-question-outline
                        </v-icon>
                        <span
                          class="text-caption ml-1 text-decoration-underline"
                          >海外にお住まいの方</span
                        >
                      </v-btn>
                    </template>
                    <p class="mb-0">
                      海外ボタンを押下後、地域を選択し、「住所」欄に住所を入力してください（郵便番号と都道府県欄は自動入力されます）<br> 例：3-4-2，Kasumigaseki，Chiyoda-ku，Tokyo 100-0013 Japan
                    </p>
                  </v-tooltip>
                </div>
              </div>
              <div
                v-if="errors && errors.adress_postalcode"
                class="text-danger"
              >
                {{ errors.adress_postalcode[0] }}
              </div>
              <div class="d-md-flex justify-start align-center pb-4 pb-md-2">
                <div style="width: 200px">
                  <v-select
                    label="都道府県"
                    :items="[
                      { label: '選択してください', id: '' },
                      ...office_prefecture,
                    ]"
                    item-text="label"
                    item-value="id"
                    outlined
                    hide-details
                    color="cyan darken-2"
                    background-color="white"
                    flat
                    dense
                    v-model="fields.adress_prefecture"
                  ></v-select>
                  <div
                    v-if="errors && errors.adress_prefecture"
                    class="text-danger"
                  >
                    {{ errors.adress_prefecture[0] }}
                  </div>
                </div>
                <div
                  class="grey--text text--darken-1 ml-md-5 mt-2 mt-md-0 text-caption"
                >
                  住民票の通り入力して下さい
                </div>
              </div>
              <v-text-field
                label="住所 ※英数字、ハイフン等の記号は半角で入力して下さい"
                outlined
                hide-details
                color="cyan darken-2"
                class="mb-2"
                background-color="white"
                dense
                v-model="fields.adress_municipality"
              ></v-text-field>
              <div
                v-if="errors && errors.adress_municipality"
                class="text-danger"
              >
                {{ errors.adress_municipality[0] }}
              </div>

              <v-text-field
                label="建物名以下 ※英数字、ハイフン等の記号は半角で入力して下さい"
                outlined
                hide-details
                color="cyan darken-2"
                background-color="white"
                dense
                class="mb-4 mb-md-0"
                v-model="fields.adress_building"
              ></v-text-field>
              <div v-if="errors && errors.adress_building" class="text-danger">
                {{ errors.adress_building[0] }}
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="d-md-flex justify-start">
      <v-simple-table class="transparent mb-5">
        <template v-slot:default>
          <tbody>
            <tr>
              <th
                width="30%"
                class="text-right px-3 bg-yellow lighten-5 bbnone"
                style="vertical-align: middle"
              >
                <span>自宅TEL</span>
              </th>
              <td class="bbnone">
                <v-text-field
                  v-model="fields.tel1"
                  placeholder="例：03-3456-7890"
                  single-line
                  outlined
                  hide-details
                  dense
                  class="my-2"
                  background-color="white"
                  color="cyan darken-2"
                ></v-text-field>
                <div v-if="errors && errors.tel1" class="text-danger">
                  {{ errors.tel1[0] }}
                </div>

                <v-text-field
                  v-model="fields.tel2"
                  placeholder="例：03-3456-7890"
                  single-line
                  outlined
                  hide-details
                  dense
                  class="my-2"
                  background-color="white"
                  color="cyan darken-2"
                ></v-text-field>
                <div v-if="errors && errors.tel2" class="text-danger">
                  {{ errors.tel1[0] }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-simple-table class="transparent mb-5">
        <template v-slot:default>
          <tbody>
            <tr>
              <th
                width="30%"
                class="text-right px-3 bg-yellow lighten-5 bbnone"
                style="vertical-align: middle"
              >
                <div class="d-flex justify-end align-center">
                  <span>自宅FAX</span>
                </div>
              </th>
              <td style="vertical-align: top" class="bbnone">
                <v-text-field
                  v-model="fields.fax1"
                  placeholder="例：03-3456-7890"
                  single-line
                  outlined
                  hide-details
                  dense
                  class="my-2"
                  background-color="white"
                  color="cyan darken-2"
                ></v-text-field>
                <div v-if="errors && errors.fax1" class="text-danger">
                  {{ errors.fax1[0] }}
                </div>

                <v-text-field
                  v-model="fields.fax2"
                  placeholder="例：03-3456-7890"
                  single-line
                  outlined
                  hide-details
                  dense
                  class="my-2"
                  background-color="white"
                  color="cyan darken-2"
                ></v-text-field>
                <div v-if="errors && errors.fax2" class="text-danger">
                  {{ errors.fax1[0] }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-card>
</template>

<script>
import SelectRegionButton from '../templates/Parts/SelectRegionButton.vue';
export default {
  components: {
    SelectRegionButton,
  },
  data() {
    return {
      office_prefecture: [],
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    (async () => {
      await this.$axios
        .get("/api/masters/office_prefecture")
        .then((response) => {
          this.office_prefecture = response.data;
        });
    })();
  },
  methods: {
    getPrefecture() {
      let _this = this.fields;
      _this.adress_building = '';
      this.getAddress(_this.adress_postalcode, function (addr) {
        _this.adress_prefecture = addr.prefecture;
        _this.adress_municipality = addr.municipality;
      });
    },
    setKaigai() {
      let _this = this.fields;
      _this.adress_prefecture = '海外';
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr .bbnone {
  border-bottom: none !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 10px 16px;
}
</style>
